import React from 'react';
import cl from './Loader.module.css';

const Loader = () => {
    return (
            <div className={cl.loader}>
                <span></span>
                <span></span>
                <span></span>
                <h2 className={cl.loaderh2}>Soon...</h2>
            </div>
        )
}

export default Loader;
