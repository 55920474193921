import React from "react";
import {useState} from "react";
import './App.css';
import Loader from './components/Loader/Loader'
import ModalWindow from "./components/ModalWindow/ModalWindow";

function App() {
    const [modal, setModal] = useState(true);

  return (
    <div className="App">
        <ModalWindow visible={modal} setVisible={setModal}>
            <Loader/>
            <p className={'moretext'}>At the moment, the site is being developed, so be patient ;)</p>
        </ModalWindow>
    </div>
  );
}

export default App;
